import React, { useState } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

export default function GraphicChart({ graphicData, showTemperature, setShowTemperature }) {

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Weather Normalization',
      },
      datalabels: {
        display: true,
        formatter: function (value, context) {
          return context.dataset.yAxisID === 'temperature' ? value : '' 
        },
        anchor: "end",
        offset: -20,
        align: "start"
      },
      legend: {
        labels: {
          usePointStyle: true,
        }
      },
      tooltip: {
        boxPadding: 10,
        bodySpacing: 5,
        boxHeight: 10,
        boxWidth: 10,
      }
    },
    scales: {
      usage: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Metered Usage (Adjusted for Weather) - kWh',
          padding: 50,
          font: {
            size: 20
          }
        },
        stepSize: 200
      },
      ...showTemperature && {
        temperature: {
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: true,
            text: 'Temperature (Degrees F)',
            padding: 50,
            font: {
              size: 20
            }
          },
          stepSize: 10,
          grid: {
            drawOnChartArea: false,
          },
        }
      },
    },
  };

  const data = {
    labels: graphicData.timePeriod.label,
    datasets: [
      {
        type: 'line',
        label: graphicData.usageValues2.label,
        backgroundColor: graphicData.usageValues2.color,
        borderColor: graphicData.usageValues2.color,
        borderWidth: 6,
        fill: false,
        data: graphicData.usageValues2.data,
        yAxisID: 'usage',
        pointStyle: 'line',
      },
      {
        type: 'line',
        label: graphicData.usageValues1.label,
        backgroundColor: graphicData.usageValues1.color,
        borderColor: graphicData.usageValues1.color,
        borderWidth: 3,
        fill: false,
        data: graphicData.usageValues1.data,
        yAxisID: 'usage',
        pointStyle: 'line',
      },
      {
        type: 'line',
        label: graphicData.confidanceRange.label,
        backgroundColor: graphicData.confidanceRange.color,
        borderColor: graphicData.confidanceRange.color,
        borderWidth: 1,
        fill: false,
        data: graphicData.confidanceRange.data,
        pointRadius: 0,
        borderDash: [5,5],
        yAxisID: 'usage',
        pointStyle: 'dash',
      },
      ...(showTemperature ? [{
        type: 'bar',
        label: graphicData.temperature1.label,
        data: graphicData.temperature1.data,
        borderColor: graphicData.temperature1.color,
        backgroundColor: graphicData.temperature1.color,
        yAxisID: 'temperature',
        pointStyle: 'rect', 
      },
      {
        type: 'bar',
        label: graphicData.temperature2.label,
        data: graphicData.temperature2.data,
        backgroundColor: graphicData.temperature2.color,
        borderColor: graphicData.temperature2.color,
        yAxisID: 'temperature',
        pointStyle: 'rect', 
      }] : [])
    ]
  }

  return <>
    <div id="graph-size-control">
    <Chart 
      type='line' 
      options={options} 
      data={data} 
      plugins={[ChartDataLabels]} 
    />
    </div>
  </>
}