import React, { useState } from 'react';
import GraphicChart from '../../atoms/chart'
import Table from '../../atoms/table'
import Form from '../form'
import { requestYearsToCompare } from '../../../services'
import { getMonthNames, addPercentage } from '../../../utils'
import { Switch, FormControlLabel } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export default function ChartTableForm() {
  const [graphicData, setGraphicData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showTemperature, setShowTemperature] = useState(false)

  const getEnergyUsage = async (startDate, endDate, billingAccount, serviceAccount) => {
    try {
      const selectedMonths = getMonthNames(startDate, endDate)      
      const yTo = endDate.getFullYear()
      const yFrom = startDate.getFullYear()
      const years = [yFrom - 1, yFrom]
      if (yFrom !== yTo) {
        years.push(yTo)
      }
      setLoading(true)
      const energyUsageComparison = await requestYearsToCompare(years, billingAccount, serviceAccount)
      if (energyUsageComparison?.data?.details) {
        const mFrom = startDate.getMonth() + 1
        const mTo = endDate.getMonth() + 1
        const dataObject = {}
        const areDifferentYears = mFrom > mTo
        years.map(year => dataObject[year] = {
          yearsInvolved: '',
          unitOfMeasure: '',
          averageDailyTemperature1: [],
          averageDailyTemperature2: [],
          usageConsumption1: [],
          usageConsumption2: [],
          weatherAdjustedUsageConsumption1: [],
          weatherAdjustedUsageConsumption2: []
        })        

        if (areDifferentYears) {
          energyUsageComparison.data.details.map(monthDetail => {
            dataObject[monthDetail.year].yearsInvolved = `'${monthDetail.year} - '${monthDetail.year + 1}`
            dataObject[monthDetail.year].unitOfMeasure = monthDetail.unitOfMeasure
            // Last part of year 1 
            if ( monthDetail.month >= mFrom) {
              dataObject[monthDetail.year].averageDailyTemperature1.push(monthDetail.averageDailyTemperature)
              dataObject[monthDetail.year].usageConsumption1.push(monthDetail.usageConsumption)
              dataObject[monthDetail.year].weatherAdjustedUsageConsumption1.push(monthDetail.weatherAdjustedUsageConsumption)
            }
            // First part of year 2
            if ( monthDetail.month <= mTo) {
              dataObject[monthDetail.year].averageDailyTemperature2.push(monthDetail.averageDailyTemperature)
              dataObject[monthDetail.year].usageConsumption2.push(monthDetail.usageConsumption)
              dataObject[monthDetail.year].weatherAdjustedUsageConsumption2.push(monthDetail.weatherAdjustedUsageConsumption)
            }
          })
        } else {
          energyUsageComparison.data.details.map(monthDetail => {
            dataObject[monthDetail.year].yearsInvolved = `'${monthDetail.year}`
            dataObject[monthDetail.year].unitOfMeasure = monthDetail.unitOfMeasure
            if ( monthDetail.month >= mFrom && monthDetail.month <= mTo) {
              dataObject[monthDetail.year].averageDailyTemperature1.push(monthDetail.averageDailyTemperature)
              dataObject[monthDetail.year].usageConsumption1.push(monthDetail.usageConsumption)
              dataObject[monthDetail.year].weatherAdjustedUsageConsumption1.push(monthDetail.weatherAdjustedUsageConsumption)
            }
          })
        }

        // Join data from last part of year 1 and first part of year 2 in case the search invloves 2 different years
        const setDataValues = (key1, key2, year1, year2) => areDifferentYears
          ? dataObject[year1][key1].concat(dataObject[year2][key2]) 
          : dataObject[year1][key1]
        
        // Chart and Table data
        setGraphicData({
          timePeriod: { 
            label: selectedMonths,
            data: []
          },
          confidanceRangeLow: {
            label: `Weather Adj ${dataObject[years[1]].unitOfMeasure} -20%`,
            data: setDataValues("weatherAdjustedUsageConsumption1", "weatherAdjustedUsageConsumption2", years[0], years[1]).map( monthlyUsage => addPercentage(monthlyUsage, -20))
          },
          confidanceRange: {
            label: `Weather Adj ${dataObject[years[1]].unitOfMeasure} +20%`,
            data: setDataValues("weatherAdjustedUsageConsumption1", "weatherAdjustedUsageConsumption2", years[0], years[1]).map( monthlyUsage => addPercentage(monthlyUsage, 20)),
            color: 'rgb(50, 50, 50)'
          },
          usageValues1: {
            label: `Weather Adj ${dataObject[years[0]].unitOfMeasure} ${dataObject[years[0]].yearsInvolved}`,
            data: setDataValues("weatherAdjustedUsageConsumption1", "weatherAdjustedUsageConsumption2", years[0], years[1]),
            color: 'rgb(120, 120, 120)'
          },
          usageValues2: {
            label: `Weather Adj ${dataObject[years[1]].unitOfMeasure} ${dataObject[years[1]].yearsInvolved}`,
            data: setDataValues("weatherAdjustedUsageConsumption1", "weatherAdjustedUsageConsumption2", years[1], years[2]),
            color: 'rgb(8, 180, 68)'
          },
          nominalUsage1: {
            label: `Nominal ${dataObject[years[0]].unitOfMeasure} ${dataObject[years[0]].yearsInvolved}`,
            data: setDataValues("usageConsumption1", "usageConsumption2", years[0], years[1])
          },
          nominalUsage2: {
            label: `Nominal ${dataObject[years[1]].unitOfMeasure} ${dataObject[years[1]].yearsInvolved}`,
            data: setDataValues("usageConsumption1", "usageConsumption2", years[1], years[2])
          },
          temperature1: {            
            label: `Avg Temp ${dataObject[years[0]].yearsInvolved}`,
            data: setDataValues("averageDailyTemperature1", "averageDailyTemperature2", years[0], years[1]),
            color: 'rgb(217, 217, 217)'
          },
          temperature2: {            
            label: `Avg Temp ${dataObject[years[1]].yearsInvolved}`,
            data: setDataValues("averageDailyTemperature1", "averageDailyTemperature2", years[1], years[2]),
            color: 'rgb(179, 198, 233)'
          }
        })        
      } else {
        setGraphicData(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setGraphicData(false)
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#08b444',
      },
    },
  });
  
return <section className="chartDataContainer">
      <div className="formContainer">
        <Form getEnergyUsage={(startDate, endDate, billingAccount, serviceAccount) => getEnergyUsage(startDate, endDate, billingAccount, serviceAccount)} loading={loading} />
        {graphicData &&<ThemeProvider theme={theme} >
          <FormControlLabel control={
            <Switch onClick={() => setShowTemperature(!showTemperature)} color="primary" />} label= "Display Temperature" />
        </ThemeProvider>}
      </div>
      {graphicData && <>
        <GraphicChart graphicData={ graphicData } showTemperature={ showTemperature } setShowTemperature={ setShowTemperature }/>
        <Table tableData={ graphicData } />
      </>}
    </section>
}