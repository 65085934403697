import React, { Component } from 'react';
import { BrowserRouter as Router, withRouter, Route } from 'react-router-dom'
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react'
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'
import TelemetryProvider from './TelemetryProvider'
import { getAppInsights } from './TelemetryService'
import { ErrorComponent } from './components/atoms/azureError'
import { LoadingComponent } from './components/atoms/azureLoading'
import Layout from './components/molecules/layout'
import { apiRequestScope } from './constants'
import './App.css'
  
// MSAL configuration
const { REACT_APP_AAD_AUTHORITY, REACT_APP_AAD_CLIENT_ID, REACT_APP_APP_INSIGHTS_KEY } = process.env
const configuration = {
  auth: {
    clientId: REACT_APP_AAD_CLIENT_ID,
    authority: REACT_APP_AAD_AUTHORITY
  },
  cache: {
    cacheLocation: 'localStorage',
  }
}

export const msalInstance = new PublicClientApplication(configuration)

class App extends Component {

  render(){
    return (<MsalProvider instance={msalInstance}>
        <Router>
          <TelemetryProvider instrumentationKey={REACT_APP_APP_INSIGHTS_KEY} after={() => { getAppInsights() }}>
            <MsalAuthenticationTemplate 
              interactionType={InteractionType.Redirect} 
              authenticationRequest={apiRequestScope} 
              errorComponent={ErrorComponent} 
              loadingComponent={LoadingComponent}         
            >
              <Route path="/" exact render={() => <Layout />} />
            </MsalAuthenticationTemplate>
          </TelemetryProvider>
        </Router>
      </MsalProvider>)
  }
}

export default withRouter(App);