import React from "react";
import { withMsal } from "@azure/msal-react";
import { msalInstance } from '../../../App'

class Logout extends React.Component {
  render() {
    return (
      <div className="loggedUser">
        {this.props.account}
        <br />
        <a href="false" onClick={(e) => {
          e.preventDefault()
          msalInstance.logoutRedirect()
        }}>Logout</a>
      </div>
    )
  }
}

export default withMsal(Logout);