import React from 'react'
import './index.css'

export default function Table({ tableData }) {
  
  const keys = Object.keys(tableData).reverse().slice(0, -1)
  const dateKey = new Date()

  return <div className='tableWrapper'>
    <table cellPadding="3" cellSpacing="0">
      <thead>
        <tr>
          <td width="300"></td>
          { tableData?.timePeriod?.label?.map((month, index) => <td align="center" key={month+index}>{ month }</td>) }
        </tr>
      </thead>
      <tbody>    
        {keys.map(key => <tr key={key+dateKey}>
          <td align="right" width="240">
            <span 
              style={ tableData[key]?.color && { display: 'block', paddingLeft: 10, marginLeft: 10, borderLeft: `15px solid ${tableData[key]?.color}`}}
            >
              {tableData[key]?.label}
            </span>
          </td>
          {tableData[key]?.data?.map((item, index) => <td align="center" key={item+index+dateKey}>{item}</td>)} 
        </tr>)}
      </tbody>
    </table>
  </div>;
}