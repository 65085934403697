import React, {Component, Fragment} from 'react'
import {withAITracking} from '@microsoft/applicationinsights-react-js'
import {ai} from './TelemetryService'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false
    }

    componentDidMount() {
        const {history, instrumentationKey} = this.props;
        const {initialized} = this.state;

        if (!initialized && instrumentationKey && history) {
            ai.initialize(instrumentationKey, history, 'Smart Inspect - UI')
            this.setState({initialized: true})
        }

        this.props.after()
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
}

TelemetryProvider.propTypes = {
  after: PropTypes.func,
  instrumentationKey: PropTypes.string.isRequired
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider))