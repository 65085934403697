import axios from "axios";
import { apiRequestScope } from "../constants";
import { msalInstance } from '../App'

export async function callApi({
  url,
  method,
  data,
  contentType = "application/json",
  header = {},
  cancelTokenSource,
  getErrorResponse = false
}) {
  
  let account = msalInstance.getActiveAccount();

  if (!account) {
    // No active account? setting one
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      account = accounts[0];
      msalInstance.setActiveAccount(account);
    } else {
      account = {};
    }
  }

  if (Object.keys(account).length === 0) {
    // Shouldn`t happen but logout user if user object it`s empty
    msalInstance.logoutRedirect();
  } else {
    var silentRequest = {
      scopes: apiRequestScope.scopes,
      account: account
    };

    let response = await msalInstance
      .acquireTokenSilent(silentRequest)
      .catch(async (error) => {
        if (error.name === "InteractionRequiredAuthError" 
        || error.name === "interaction_required" 
        || error.name ===  "login_required" 
        || error.name ===  "consent_required") {
          return await msalInstance
            .acquireTokenRedirect(silentRequest)
            .catch((error) => {
              console.log(error);
              msalInstance.logoutRedirect();
            });
        }
      });

    const { REACT_APP_API_URL } = process.env
    
    return axios({
      headers: {
        "Authorization": `Bearer ${response.accessToken}`,
        "Content-type": contentType,
        ...header,
      },
      method,
      url: `${REACT_APP_API_URL}${url}`,
      data: data || null,
      cancelToken: cancelTokenSource && cancelTokenSource.token
    }).catch((err) => {
      // track error
      if (axios.isCancel(err)) {
        return {canceled: true}
      } else if( getErrorResponse ) {
        // handle error        
        return Promise.reject(err)
      } else {
        return [];
      }
    });
  }
}
