import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { addOrSubstractDate, getParameter } from '../../../utils'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-datepicker/dist/react-datepicker.css'

const today = new Date()
const sixMonthsLessFromNow = addOrSubstractDate(new Date(), 6, '-', 'm')

export default function Form({ getEnergyUsage, loading }) {
  const [billingAccount, setBillingAccount] = useState(getParameter('ba'))
  const [serviceAccount, setServiceAccount] = useState("")
  const [startDate, setStartDate] = useState(new Date(sixMonthsLessFromNow))
  const [endDate, setEndDate] = useState(new Date())
  
  let elevenMonthsFrom = dateFrom => addOrSubstractDate(dateFrom, 11, '+', 'm')
  const maxDateTo = elevenMonthsFrom(startDate) > today ? today : elevenMonthsFrom(startDate)
  return (
    <>
      <div>
        <label>Billing Account</label>
        <input type="number" value={billingAccount} onChange={e => setBillingAccount(e.target.value)} />
      </div>
      <div>
        <label>Service Account</label>
        <input type="number" value={serviceAccount} onChange={e => setServiceAccount(e.target.value)} />
      </div>
      <div>
        <label>Select Month Range</label>
        <div className="dateRangeContainer">
          <DatePicker
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              if (date > endDate) {
                setEndDate(addOrSubstractDate(date, 3, '+', 'm'))
              }
              if (endDate > elevenMonthsFrom(date)) {
                setEndDate(elevenMonthsFrom(date))
              }
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            maxDate={today}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            maxDate={maxDateTo}
            minDate={startDate}
          />
        </div>
      </div>
      <div className="buttonContainer">
        <button {...loading && {disabled: true}} type="button" onClick={() => getEnergyUsage(startDate, endDate, billingAccount, serviceAccount)}>Search</button>
      </div>
    </>
  );
    
}