// Function to add or substract x months or years
export function addOrSubstractDate(date, dateModifyer, operator, type ) {
  let _date = new Date(date.getTime())
  var modifier = (operator === '+') ? 1 : -1
  if (type === 'm') {
    return new Date(_date.setMonth(_date.getMonth()  + (modifier * dateModifyer)))
  } 
  if (type === 'y') {
    return new Date(_date.setFullYear(_date.getFullYear()  + (modifier * dateModifyer)))
  }
}

export const getMonthNames = (from, to) => {
  var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  var arr = []
  var fromDate = new Date('1 ' + formatDate(from))
  var toDate = new Date('1 ' + formatDate(to))
  var fromYear =  fromDate.getFullYear()
  var toYear =  toDate.getFullYear()
  var diffYear = (12 * (toYear - fromYear)) + toDate.getMonth()

  for (var i = fromDate.getMonth(); i <= diffYear; i++) {
    arr.push(monthNames[i%12])
  }        

  return arr
}

export const formatDate = value => {
  let date = new Date(value)
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.toLocaleString('default', { year: 'numeric' })
  return month + ' ' + year
}

export const addPercentage = (value, percentage) => {
  const pct = parseFloat(percentage) / 100
  value += (value * pct)
  return Math.round(value)
}

export const getLoggedInUser = async msalInstance => {
  try {
    const activeAccount = msalInstance.getActiveAccount()
    if (activeAccount && activeAccount.username) {
      return activeAccount
    } else {
      const accounts = await msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const currentAccount = accounts[0]
        await msalInstance.setActiveAccount(currentAccount);
        return currentAccount
      }
    }
  } catch (error) {
    
  }
  return null
}

export const getParameter = param => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param)
}
