import React, { useEffect, useState } from 'react';
import logo from './logo.svg'
import ChartTableForm from '../chartTableForm'
import { getLoggedInUser } from '../../../utils'
import { msalInstance } from '../../../App'
import Logout from '../../atoms/logout'

export default function Layout() {

  useEffect(() => {
    const onInit = async () => {
      const currentUser = await getLoggedInUser(msalInstance)      
      setAccount(currentUser || {})
    }    
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[msalInstance])
  
  const [ account, setAccount] = useState(null)
  return <main>
    <header>
      <img src={logo} className="logo" alt="Eversource Logo" />
      <h1>Weather Adjusted Energy Usage</h1>
      <Logout account={account?.name} />
    </header>
    <ChartTableForm />
  </main>
}